import * as React from 'react';
import Typography from '@mui/material/Typography';
import MuiLink from '@mui/material/Link';
import {Container} from "@mui/material";

export default function Copyright() {
  return (
      <Container>
          <Typography color="text.secondary" align="center">
              {'Copyright © '}
              <MuiLink color="inherit" href="/imprint">
                  GroupMate App - Christian Wilhelm
              </MuiLink>{' '}
              {new Date().getFullYear()}
              {'.'}
          </Typography>
      </Container>
  );
}
