import * as React from 'react';
import {AppBar, Box, Container, Toolbar} from "@mui/material";
import Link from "./Link";

export default function PageHeader() {
    return (
        <>
            <AppBar elevation={3} sx={{backgroundColor: "#000000"}} position={"fixed"}>
                <Container maxWidth={"lg"}>
                    <Toolbar sx={{justifyContent: 'space-between', color: "#FFFFFF"}}>
                        <Box component={"img"} src={"/groupmate-header.png"} height={"40px"}/>
                        <Box>
                            <Link underline={"none"} color={"inherit"} varian={"h6"} to={"/"} ml={2}>
                                Home
                            </Link>
                            <Link underline={"none"} color={"inherit"} varian={"h6"} to={"/privacy-policy"} ml={2}>
                                Datenschutz
                            </Link>
                            <Link underline={"none"} color={"inherit"} varian={"h6"} to={"/imprint"} ml={2}>
                                Impressum
                            </Link>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Box sx={{mt: 8}}/>
        </>
    );
}
