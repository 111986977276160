import * as React from 'react';
import {Helmet} from "react-helmet";

export default function PageDefault() {
    return (
        <Helmet
            defaultTitle={"GroupMate App"}
        >
            <style type={"text/css"}>{`
                body {
                    overflow-y: scroll;
                }
            `}
            </style>
        </Helmet>
    )
}